if (!window.sentry_release) {
  window.sentry_release = ___SENTRY_RELEASE___;
}
if (!window.sentry_url) {
  window.sentry_url = ___SENTRY_URL___;
}
if (!window.sentry_environment) {
  window.sentry_environment = ___SENTRY_ENV___;
}
if (!window.sentry_skip_global_instance) {
  Sentry.init({
    dsn: window.sentry_url,
    integrations: [Sentry.browserTracingIntegration()],
    transportOptions: {fetchOptions: {keepalive: false}},
    tracesSampleRate: 1.0,
    release: window.sentry_release,
    environment: window.sentry_environment
  });
}
